class ModalWindow {
    public compiled: any;
    public instance: any;
    public source: any;
    public onClose: Function = null;
    public disableOverlayClickClose: boolean = false;
    public previousOverlayScroll: number = null;

    constructor(compiled: any, instance: any) {
        this.compiled = compiled;
        this.instance = instance;
    }

    public hide() {
        this.compiled.style.display = "none";
    }

    public show() {
        this.compiled.style.display = "";
    }
}

export class ModalCompiled {
    public element: HTMLElement;
    public instance: any;

    constructor(element: HTMLElement, instance: any) {
        this.element = element;
        this.instance = instance;
    }
}

export interface IModalCompiler {
    compile(selector: any, data: any): ModalCompiled;
}

export class Modal {
    private compiler: IModalCompiler;
    private stack: Array<ModalWindow> = [];
    private overlay: HTMLDivElement | null = null;

    constructor(compiler: IModalCompiler) {
        this.compiler = compiler;
        document.addEventListener("keydown", this.handleEscape.bind(this));
    }

    public open(selector: any, data: any): Function {
        const compiled = this.compiler.compile(selector, data);
        const modalWin = Modal.create(compiled);
        if (!this.overlay) {
            this.createOverlay();
        } else {
            modalWin.previousOverlayScroll = this.overlay.scrollTop;
        }
        modalWin.onClose = data.onClose || null;
        modalWin.disableOverlayClickClose = !!data.disableOverlayClickClose;
        this.stack.forEach(modal => modal.hide());
        this.stack.push(modalWin);
        this.overlay.appendChild(modalWin.compiled);
        modalWin.compiled.style.display = "none";
        this.fadeIn(modalWin.compiled, 250);
        return () => {
            this.destroyModal(modalWin);
        };
    }
    public closeAll(): void {
        while (this.stack.length > 0) {
            this.closeTopModal();
        }
    }

    private fadeIn(element: HTMLElement, duration: number) {
        element.style.opacity = "0";
        element.style.display = "";
        element.className = "close-overlay"

        let start = performance.now();

        const fadeInAnimation = (time: any) => {
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;

            element.style.opacity = `${timeFraction}`;

            if (timeFraction < 1) {
                requestAnimationFrame(fadeInAnimation);
            }
        };

        requestAnimationFrame(fadeInAnimation);
    }

    private handleEscape(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.closeTopModal();
        }
    }

    private createOverlay(): void {
        this.overlay = document.createElement("div");
        this.overlay.className = "modal-overlay";
        this.overlay.addEventListener("click", event => {
            if (event.target.className === "close-overlay") {
                if (!this.stack[this.stack.length - 1].disableOverlayClickClose) {
                    this.closeTopModal();
                }
            }
        });

        document.body.appendChild(this.overlay);
        document.body.style.overflow = "hidden";
    }

    private closeTopModal(): boolean {
        if (this.stack.length === 0) {
            return false;
        }
        let top = this.stack[this.stack.length - 1];
        if (top.disableOverlayClickClose) {
            return false;
        }
        this.destroyModal(top);
        if (this.stack.length !== 0) {
            this.stack[this.stack.length - 1].show();
        }
        return true;
    }

    private destroyModal(modal: ModalWindow) {
        modal.instance.unmount();
        if (this.overlay) {
            this.overlay.removeChild(modal.compiled);
        }
        if (modal.onClose instanceof Function) {
            modal.onClose();
        }
        this.stack.splice(this.stack.indexOf(modal), 1);
        if (this.stack.length === 0) {
            this.removeOverlay();
        } else {
            if (modal.previousOverlayScroll !== null) {
                this.overlay.scrollTop = modal.previousOverlayScroll;
            }
        }
    }

    private removeOverlay(): void {
        if (this.overlay) {
            document.body.style.overflow = "";
            this.overlay.remove();
            this.overlay = null;
        }
    }

    private static create(compiled: ModalCompiled): ModalWindow {
        const wrapper = document.createElement("div");
        wrapper.appendChild(compiled.element);
        const modal = new ModalWindow(wrapper, compiled.instance);
        modal.source = compiled;
        return modal;
    }
}
