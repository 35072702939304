type DropdownItem = {
    name: string;
    styles?: Partial<CSSStyleDeclaration>;
    onClick: () => void;
};

type DropdownBinding = {
    value: {
        id: string;
        items: DropdownItem[];
        styles?: Partial<CSSStyleDeclaration>;
        marginTop?: number;
        staticTop?: number;
    };
};

const baseItemStyle: Partial<CSSStyleDeclaration> = {
    padding: "4px 10px",
    cursor: "pointer",
    minWidth: "100px",
    textDecoration: "none",
    color: "#000",
    backgroundColor: "transparent"
};

const dropdownStyle: Partial<CSSStyleDeclaration> = {
    top: "60px",
    display: "none",
    flexDirection: "column",
    position: "fixed",
    fontFamily: "Arial",
    fontSize: "12px",
    fontStyle: "normal",
    backgroundColor: "#ffffff",
    borderColor: "#dbdbdb",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "12px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    overflow: "hidden"
};

function createDropdownItem(item: DropdownItem): HTMLElement {
    let itemElement = document.createElement("a");
    itemElement.textContent = item.name;
    itemElement.href = "javascript:void(0);";
    Object.assign(itemElement.style, baseItemStyle, item.styles);
    itemElement.addEventListener("click", item.onClick);
    return itemElement;
}

function onBindOrUpdate(el: HTMLElement, binding: DropdownBinding) {
    const fullId = "dropdown__" + binding.value.id;
    const existingDropdown = document.getElementById(fullId);
    if (existingDropdown) {
        existingDropdown.remove();
    }

    const dropdown = document.createElement("div");
    dropdown.setAttribute("class", "dropdown");
    dropdown.setAttribute("id", fullId);
    Object.assign(dropdown.style, dropdownStyle, binding.value.styles);

    binding.value.items.forEach(item => {
        dropdown.appendChild(createDropdownItem(item));
    });

    const handleClickOutside = (event: MouseEvent) => {
        if (!dropdown.contains(event.target as Node)) {
            dropdown.style.display = "none";
            window.removeEventListener("click", handleClickOutside);
        }
    };

    el.addEventListener("click", event => {
        event.preventDefault();
        const offset = el.getBoundingClientRect();
        dropdown.style.left = `${offset.left}px`;
        dropdown.style.top = `${binding.value.staticTop ||
            offset.top + el.clientHeight + (binding.value.marginTop || 0)}px`;

        dropdown.style.display = "flex";
        setTimeout(() => window.addEventListener("click", handleClickOutside), 1);
    });

    el.appendChild(dropdown);
}

export default {
    mounted: onBindOrUpdate,
    unmounted(el: HTMLElement) {
        const fullId = "dropdown__" + el.id;
        const dropdown = document.getElementById(fullId);
        dropdown?.remove();
    },
    updated: onBindOrUpdate
};
