type BindingType = {
    value: {
        scrollOf?: string;
        scrollFor?: string;
        scroll: () => void;
    };
};

function getParent(bindingValue: BindingType["value"]): HTMLElement | Window {
    if (bindingValue.scrollOf) {
        return document.querySelector(bindingValue.scrollOf) as HTMLElement;
    }
    return window;
}

function getDocument(bindingValue: BindingType["value"]): Document | HTMLElement {
    if (bindingValue.scrollFor) {
        return document.querySelector(bindingValue.scrollFor) as HTMLElement;
    }
    return document;
}

export default {
    mounted: function(el: any, binding: BindingType) {
        const span = 250;
        const parent = getParent(binding.value);
        const doc = getDocument(binding.value);
        const handler = function() {
            const visible = !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
            if (!visible) {
                return;
            }
            const parentScrollTop =
                "scrollTop" in parent
                    ? parent.scrollTop
                    : document.documentElement.scrollTop || document.body.scrollTop;
            const windowHeight = window.innerHeight;
            const bottom = parentScrollTop + windowHeight;
            const documentHeight = "scrollHeight" in doc ? doc.scrollHeight : document.documentElement.scrollHeight;
            const border = documentHeight - span;

            if (bottom > border) {
                binding.value.scroll();
            }
        };

        parent.addEventListener("scroll", handler);
        el["_infinityScrollHandler"] = handler;
    },
    unmounted: function(el: any, binding: BindingType) {
        const parent = getParent(binding.value);
        const handler = el["_infinityScrollHandler"];
        if (handler) {
            parent.removeEventListener("scroll", handler);
        }
    }
};
